import React, {Component} from 'react';
import {getURLParameter} from "../utils/HelpfulFunction";
import {QRCodeSVG} from 'qrcode.react';
import {appDatabasePrimaryFunctions, firebase} from '../../base';

class ImageViewer extends Component {
    constructor(props) {
        super(props);
        const code = getURLParameter('code');
        const pin = getURLParameter('pin');
        const imageToShow = getURLParameter('image');
        const showQrCode = getURLParameter('qrcode');
        const uid = getURLParameter('uid');
        this.state = {
            imageToShow: imageToShow,
            code: code,
            showQrCode: showQrCode,
            pin: pin,
            uid: uid
        };
    }

    componentDidMount() {
        document.body.style.backgroundColor = "white";
        this.props.loadPage();
        if(this.state.uid && typeof this.state.uid === "string"){
            appDatabasePrimaryFunctions.ref(`userAnswers/${this.state.uid}/opens`).set(firebase.database.ServerValue.increment(1))
        }
    }

    componentWillUnmount() {
        document.body.style.backgroundColor = "black";
    }

    render() {
        const templateImage = this.state.imageToShow;
        const code = this.state.code;
        const showQrCode = this.state.showQrCode === "true";
        const pin = this.state.pin;
        return (
            <div style={{textAlign:'center', backgroundColor:"white", minHeight:"100%"}}>
                <div style={{width: "100%", maxWidth: 400, marginLeft: "auto", marginRight: "auto"}}>
                    <img style={{maxWidth: "100%", maxHeight: "100%", backgroundColor: "white"}} src={templateImage} alt=""/>
                </div>
                {showQrCode &&
                    <div style={{marginTop: 20, maxWidth: 400, marginLeft: "auto", marginRight: "auto"}}>
                        <QRCodeSVG value={code} size={"50%"} bgColor="white"/>
                    </div>
                }
                {code &&
                    <div style={{marginTop: showQrCode?10:20, marginBottom: 20}}>
                        <p style={{color: "black", fontSize: showQrCode?"2vh":"4vh", margin:0}}>{code}</p>
                    </div>
                }
                {pin &&
                    <div style={{marginTop: 20, marginBottom:20}}>
                        <p style={{color: "black", fontSize: "4vh", margin:0}}>{pin}</p>
                    </div>
                }
            </div>
        )
    }
}

export default ImageViewer;
